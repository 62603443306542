import React from "react"
import { Container, Row, Col, Button } from "reactstrap"

import GenericAutomationChartContainer from "src/containers/charts/GenericAutomationChartContainer"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import Heading from "src/components/index/Heading"
import TestimonialCard from "src/components/TestimonialCard"

import chrisAvatar from "src/assets/img/testimonials/chris-medium600.jpg"
import secretaryAvatar from "src/assets/img/testimonials/secretary-small-square.jpg"
import exampleICL from "src/assets/pdf/example-icl.pdf"
import exampleInvoice from "src/assets/pdf/example-invoice.pdf"

const SantisSecretarialTaskAutomationCaseStudy = () => {
  return (
    <Layout>
      <Seo
        title="Carebit: Case studies | Santis and the automating secretarial tasks"
        pathname="/case-studies/santis-automating-secretarial-tasks"
        description="Learn how Santis, a London prostate cancer clinic, saved hours of time and hundreds of pounds by automating secretarial tasks with Carebit."
      />
      <div className="Hero-waves">
        <div className="Hero-label">
          <h1>Case study: automating secretarial tasks</h1>
        </div>
      </div>
      <div>
        <Container>
          <section>
            <Row className="u-centerFlex">
              <Col lg={6} md={12} className="SplitContentRow-text">
                <Row>
                  <Heading title="Introduction">Santis</Heading>
                </Row>
                <p>
                  <a
                    href="https://www.santishealth.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Santis
                  </a>{" "}
                  is a leading London clinic specialising in advanced robotic
                  surgery for prostate cancer. They treat hundreds of men every
                  year, and introduced Carebit to automate many administrative
                  tasks that are critical to running their practice.
                </p>

                <p>
                  The initial results were astonishing. In the first full month
                  of service,{" "}
                  <span className="u-fontBold">
                    Carebit performed 38 hours worth of work
                  </span>
                  , lifting a substantial burden from their secretary.
                </p>
              </Col>
              <Col lg={6} md={12} className="SplitContentRow-graphic">
                <GenericAutomationChartContainer
                  chartData={[{ x: "Carebit", y: 38 }]}
                  countUpTextBefore=" "
                  countUpTextAfter=" hours worth of admin work a month"
                />
              </Col>
            </Row>
          </section>

          <section>
            <Row>
              <Col md={5} className="SplitContentRow-text">
                <Row>
                  <Heading title="The problem">
                    The initial need for automation
                  </Heading>
                </Row>

                <p>
                  Santis was launched in July 2017, and from the beginning the
                  three founders decided that they would be the first prostate
                  cancer group in the UK to actively record patient quality of
                  life and outcomes after surgery using questionnaires known as
                  PROMs (Patient Reported Outcome Measures).
                </p>
                <p>
                  Prostate cancer surgery performed by relatively inexperienced
                  surgeons can lead to a significantly impaired quality of life
                  for patients, with issues such as impotence and incontinence
                  commonly reported. Santis was convinced that their dedication
                  to delivering the best possible outcomes for patients coupled
                  with their experience, skill and facilities, would be
                  evidenced in the data collected.
                </p>

                <p>
                  This impartially-reported digital data would be tremendously
                  useful not only academically in promoting to other surgeons
                  the benefits of the new complex surgical techniques employed
                  by Santis, but also for prospective patients by helping them
                  to see clearly how men of a similar age and cancer profile had
                  fared after undergoing surgery, and therefore why they might
                  want to select Santis for treatment.
                </p>

                <p>
                  However, a key issue was one of resources. Given the
                  anticipated patient volumes of hundreds of patients per year,
                  Santis simply did not have the staff resources to post or
                  email the right questionnaires at the right time to patients,
                  and then for staff to enter in the responses into a computer
                  manually. There had to be a better way.
                </p>
              </Col>
              <Col md={{ size: 5, offset: 2 }} className="SplitContentRow-text">
                <Row>
                  <Heading title="The solution">
                    Automated questionnaires emailed out each day
                  </Heading>
                </Row>

                <p>
                  Santis worked with Carebit to implement an automated system
                  that would automatically enrol a patient into the
                  questionnaire service when they made a booking for surgery.
                  The patient would then be emailed at set intervals around this
                  surgery date - 7 days before, 7 days after, 4 weeks after,
                  3/6/12/18 months after and then 2/3/4/5 years after.
                </p>

                <p>
                  In this email was a unique link to a secure page where the
                  patients were told what the questionnaire was for, and then
                  invited to submit their encrypted responses electronically to
                  the Carebit database. Patients were able to fill out the
                  questionnaires on the device that best suited them: on a
                  desktop at work or on a smartphone or iPad at home.
                </p>

                <p>
                  As of April 2019, Santis has collected over 3,100
                  questionnaires from over 330 patients. The service runs every
                  morning at 6am and is totally hands-off, with no secretarial
                  or clinical effort required. Santis can get on with their
                  focus of delivering the best patient care while it runs
                  silently in the background.
                </p>
              </Col>
            </Row>
          </section>
        </Container>

        <div className="Index-gradient">
          <div className="ColouredDivider Index-dividerBlueGreen">
            <Container>
              <section>
                <TestimonialCard
                  author="Professor Christopher Eden"
                  authorSubtext="Leading robotic radical prostatectomy expert, Santis"
                  avatarSrc={chrisAvatar}
                >
                  <p>
                    “The automation ability of Carebit has allowed us to collect
                    over 37,000 individual patient responses regarding their
                    perception of their outcomes in a way that{" "}
                    <span className="u-fontBold">
                      would have been quite impossible manually
                    </span>
                    , and has allowed us to lead the UK in this field.”
                  </p>
                </TestimonialCard>
              </section>
            </Container>
          </div>
        </div>

        <Container>
          <section>
            <Row className="u-centerFlex">
              <Col lg={6} md={12} className="SplitContentRow-text">
                <Row>
                  <Heading title="Further automation">Clinic lists</Heading>
                </Row>
                <p>
                  Each week, a clinic list must be prepared by the secretary for
                  the Santis consultant. It’s a simple list of all the patients
                  the consultant is seeing that week, including key patient
                  data, GP information and what the treatment/service being
                  provided is. The hospital where the patient is being seen also
                  needs the data for their records.
                </p>

                <p>
                  Before Carebit, this was done by hand which took approximately
                  3 hours each week. With Carebit, the task runs automatically
                  at 6am every Tuesday morning and a draft list is emailed
                  automatically to the consultant’s secretary.
                </p>
              </Col>
              <Col lg={6} md={12} className="SplitContentRow-graphic">
                <GenericAutomationChartContainer
                  chartData={[{ x: "Carebit", y: 12 }]}
                  countUpTextBefore=" "
                  countUpTextAfter=" hours saved per month with Carebit"
                />
              </Col>
            </Row>
          </section>

          <section>
            <Row className="u-centerFlex">
              <Col lg={6} md={12} className="SplitContentRow-graphic">
                <GenericAutomationChartContainer
                  chartData={[{ x: "Carebit", y: 126456 }]}
                  countUpTextBefore="£"
                  countUpTextAfter=" collected with automated billing"
                />
              </Col>

              <Col lg={6} md={12} className="SplitContentRow-text">
                <Row>
                  <Heading title="Further automation">
                    Automatic invoice issuance & online payment
                  </Heading>
                </Row>
                <p>
                  Having to issue invoices to self-pay patients and ensuring
                  they were paid in a timely manner was consuming a lot of time
                  for Santis. As a small clinic, it was hard to keep on top of
                  who had paid. Worse still, sometimes secretaries would forget
                  to issue an invoice, meaning more time had to be spent chasing
                  up patients months after their appointment, costing both time
                  and money.
                </p>

                <p>
                  With Carebit, PDF invoices are generated automatically when a
                  self-pay patient has made an appointment and are emailed
                  directly to them. As the invoices include a convenient link to
                  pay online using Carebit, patients can pay instantly 24/7
                  using a debit or credit card - which has proved to be very
                  popular - and frequently patients pay just minutes after
                  receiving the invoice.
                </p>

                <p>
                  When a patient pays by card, Carebit automatically marks the
                  invoice as paid and transfers the payment to the doctor
                  directly 7 days later, so Santis doesn’t need to lift a
                  finger. Santis has even configured Carebit to cancel self-pay
                  appointments if invoices have not been paid by the day of the
                  appointment, ensuring a 100% collections rate.
                </p>

                <div>
                  <a
                    href={exampleInvoice}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Button color="secondary" size="md">
                      View example invoice &rarr;
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
          </section>
        </Container>

        <div className="Index-gradient">
          <div className="ColouredDivider Index-dividerBlueGreen">
            <Container>
              <section>
                <TestimonialCard
                  author="Mrs Simone Castle"
                  authorSubtext="Secretary, Santis"
                  avatarSrc={secretaryAvatar}
                >
                  <p>
                    “Carebit is a user-friendly practice management platform
                    which is easy to learn and use. I rely on its many features
                    to run Santis, where it assists tremendously in the smooth
                    running of the office. For example, the legally-required
                    initial consultation letter for patients is generated,
                    appended with the doctor’s signature, and emailed to the
                    patient as soon as an initial consultation is booked. If a
                    patient is self-pay, an invoice is created and emailed to
                    the patient directly prior to the consultation taking place
                    and the consultant can view an interactive, up-to-date
                    clinic list at any time.{" "}
                    <span className="u-fontBold">
                      All of this happens without my having to do anything
                    </span>
                    .
                  </p>

                  <p>
                    <span className="u-fontBold">
                      The time it has saved me is tremendous, leaving me to be
                      able to run a more efficient, reliable office
                    </span>
                    . I would strongly recommend Carebit to any clinic no matter
                    how big or small. The team at Carebit listen to and
                    understand how important the role I play in the company is,
                    and assist me by adding features to Carebit as I discover
                    the need for them.”
                  </p>
                </TestimonialCard>
              </section>
            </Container>
          </div>
        </div>

        <Container>
          <section>
            <Row className="u-centerFlex">
              <Col lg={6} md={12} className="SplitContentRow-text">
                <Row>
                  <Heading title="Further automation">
                    Initial consultation letters
                  </Heading>
                </Row>
                <p>
                  When a patient first makes an appointment to see a private
                  doctor, legally that doctor must issue to the patient a letter
                  outlining their fees, quality commitment information and their
                  financial interest in the clinic. The letter also states that
                  in the event of private medical insurance not covering any
                  fees then the patient is liable to pay them, so it forms an
                  important contract between the patient and the clinic.
                </p>

                <p>
                  As patient volumes began to increase in mid-2018, Santis were
                  spending a lot of time sending out these letters. By
                  automating them through Carebit so that they were generated
                  and emailed as soon as the patient’s first booking was made,
                  it’s one less thing to worry about. The letters even include
                  the doctor’s signature.
                </p>

                <div>
                  <a
                    href={exampleICL}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Button color="secondary" size="md">
                      View example letter &rarr;
                    </Button>
                  </a>
                </div>
              </Col>

              <Col lg={6} md={12} className="SplitContentRow-graphic">
                <GenericAutomationChartContainer
                  chartData={[{ x: "Carebit", y: 83 }]}
                  countUpTextBefore=" "
                  countUpTextAfter=" initial consultation letters issued"
                />
              </Col>
            </Row>
          </section>
        </Container>
      </div>
    </Layout>
  )
}

export default SantisSecretarialTaskAutomationCaseStudy
